import { ApiCollection }   from 'Collections/ApiCollection';
import DepositModel        from 'Models/invoice/DepositModel';
import DepositPaymentModel from 'Models/invoice/DepositPaymentModel';
import ContractModel       from 'Models/sales/ContractModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class DepositDashboardStore extends AbstractModelXStore {

	public collectionContract = new ApiCollection(ContractModel);
	public deposit = new DepositModel();
	public depositPaymentCollection = new ApiCollection(DepositPaymentModel);

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.deposit
				.setId(id)
				.fetch()
				.then(async () => {
					await this.collectionContract
						.listBy([this.deposit.getUrn('billingGroup')], 'billingGroupUrn');
				}),

			await this.depositPaymentCollection
				.listBy([id], 'deposit'),
		]);
	}

	public get contract() {
		return this.collectionContract.first();
	}
}